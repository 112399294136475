<template>
	<div class="proc-btns-mobile">
		<v-menu left offset-y bottom max-height="520">
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="proc__menu-btn"
					   icon
					   v-bind="attrs"
					   v-on="on">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>

			<v-list dense class="proc-btns-mobile__menu-list">
				<v-list-item v-for="(item, index) of effectiveButtons" :key="'menuBtn'+index"
							 link @click="onButton(item)">
					<v-img v-if="item.src" :src="item.src" :title="item.CAPTION"/>
					<v-list-item-title>{{
							item.CAPTION
						}}
					</v-list-item-title>
				</v-list-item>
				<v-divider v-if="effectiveButtons && effectiveButtons.length"/>
				<v-list-item v-if="meta && meta.HELPURL" :href="meta.HELPURL" target="_blank">
					<v-icon>mdi-help-circle-outline</v-icon>
					<v-list-item-title>Справка</v-list-item-title>
				</v-list-item>
				<v-list-item @click="$emit('reload')">
					<v-icon>mdi-database-refresh-outline</v-icon>
					<v-list-item-title>Перезагрузить выборку</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>

import Config from "@/config";
import Vue from "vue";
import vp from "@/mixins/vp";

export default {
	name: "proc-btns-mobile",
	props: {
		disabled: Boolean,
		meta: {},
		buttons: {},
		currentItem: {},
		headers: {},
		updateCounter: {},
	},
	components: {},
	mixins: [vp],
	data: () => ({
		isIniting: true,
		visibleButtons: null,
		menuButtons: null,
	}),
	computed: {
		effectiveButtons() {
			return this.buttons?.filter(btn => !btn._isHidden);
		},
	},
	watch: {
		// 1. обновляем по явной команде
		updateCounter() {
			this.updateButtonsVisibility();
		},
		// 2. обновляем по изменению текущего элемента
		currentItem(val, old) {
			this.updateButtonsVisibility();
		},
		// 3. обновляем по изменению заголовков
		headers() {
			this.updateButtonsVisibility();
		},
		// 3. обновляем по составу кнопок
		buttons() {
			this.updateButtonsVisibility();
		},
	},
	methods: {
		onButton(btn) {
			this.$emit("click", btn);
		},
		updateButtonsVisibility() {
			//console.log("updateButtonsVisibility", this.buttons);

			this.buttons?.forEach(btn => Vue.set(btn, "_isDisabled", false));
			this.buttons?.forEach(btn => Vue.set(btn, "_isHidden", false));

			if (this.currentItem) {
				//console.log("currentItem", Object.keys(this.currentItem));

				Object.keys(this.currentItem).forEach(key => {
					let id, prop, value = false;
					if (key.match(/^OPERVIS\$(\d+)$/i)) {
						id = key.replace(/^OPERVIS\$(\d+)$/i, "$1");
						prop = "_isHidden";
						value = this.currentItem[key] === 0;
					} else if (key.match(/^OPERACT\$(\d+)$/i)) {
						id = key.replace(/^OPERACT\$(\d+)$/i, "$1");
						prop = "_isDisabled";
						value = this.currentItem[key] === 0;
					}
					const btn = this.buttons?.find(el => Math.abs(Number(el.ID_OPERATION)) === Math.abs(Number(id)));
					if (btn) Vue.set(btn, prop, value);
				});
			} else if (this.headers) {
				// visibility is based on ALL items availability
				let ids = [];
				//console.log("headers", this.headers.map(el=>el.PROCPARAM));
				this.headers.forEach(item => {
					if (item.PROCPARAM.match(/^OPERVIS\$(\d+)$/i) || item.PROCPARAM.match(/^OPERACT\$(\d+)$/i)) {
						const id = item.PROCPARAM.replace(/^.+\$(\d+)$/i, "$1");
						//console.log(item.PROCPARAM + " " + id);
						if (!ids.includes(id)) ids.push(Math.abs(Number(id)));
					}
				});
				//console.log("HIDDEN IDS", ids);

				this.buttons?.forEach(btn => {
					if (ids.includes(Math.abs(Number(btn.ID_OPERATION)))) Vue.set(btn, "_isHidden", true);
				});
			}
		},
		isButtonDisabled(btn) {

			if (btn._isDisabled) return true;
			if (this.currentItem) return false;

			if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_DOWNLOAD) return true;
			if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_OPEN) return true;
			if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_DELETE) return true;

			return false;
		},
	},
	mounted() {
		this.updateButtonsVisibility();
	},
}
</script>

<style lang="scss">
.proc-btns-mobile {
	height: 40px;
	display: flex;
	align-items: center;
}
</style>