<template>
	<v-dialog v-model="params.isVisible"
			  content-class="proc-dialog"
			  persistent
			  :fullscreen="isFullscreen"
			  :scrollable="!isLG"
			  :width="dialogWidth">
		<v-card v-if="hasContent" class="proc-dialog__card" :height="height">
			<!--<div v-if="formTitle && isLG" class="panels__header d-flex align-center justify-space-between flex-grow-0 pl-1">
				<h3>{{ formTitle }}</h3>
				<v-icon small @click="onClose">mdi-close</v-icon>
			</div>-->
			<v-card-actions class="proc-dialog__card-actions d-flex justify-space-between align-center">
				<v-btn
					color="primary"
					text
					class="order-first order-lg-last ml-lg-1"
					:disabled="isUpdatingSave || isUpdatingSaveAndClose"
					@click="onClose">
					<v-icon>mdi-close</v-icon> <span class="d-lg-none">Отмена</span>
				</v-btn>

				<div class="proc-dialog__card-actions-content">
					<v-card-title v-if="isLG" class="proc-dialog__title">
						{{ title }}
					</v-card-title>

					<div v-if="withCardButtons">
						<v-btn
							color="primary"
							text
							:loading="isUpdatingSave"
							:disabled="isUpdatingSaveAndClose || !isChanged"
							@click="onSave">
							Применить
						</v-btn>
						<v-btn
							color="primary"
							:loading="isUpdatingSaveAndClose"
							:disabled="isUpdatingSave || !isChanged"
							@click="onSaveAndClose">
							Сохранить
						</v-btn>
					</div>
				</div>
			</v-card-actions>

			<v-card-title v-if="isFormView && !isLG" class="proc-dialog__title">
				{{ title }}
			</v-card-title>
			<!--			<v-divider/>-->

			<v-card-text v-if="params.isVisible" class="proc-dialog__content">

				<!-- это форма, открываемая в диалоге - содержит набор панелей + (опционально) кнопки для сохранения данных в форме (это называется "карточкой") -->
				<div v-if="isFormView" class="d-flex flex-column flex-grow-1 pa-1 pa-lg-0">
					<!--					<div class="proc-dialog-panels__header d-flex justify-space-between align-center flex-grow-0">
											<h3>{{ formTitle }}</h3>
											<v-btn icon @click="onClose">
												<v-icon>mdi-close</v-icon>
											</v-btn>
										</div>-->

					<!-- todo clear form controls on form change -->
					<div v-if="isLoading" class="proc-dialog__loader">
						<v-progress-circular indeterminate
											 color="grey lighten-2"/>
						<p class="mt-8">Пожалуйста, подождите...</p>
					</div>
					<panels v-else-if="data" :data="data" :dialog-params="params" :main-proc-id="params.mainProcId"/>
				</div>

				<!--				<proc-dialog-panels v-if="params.formId"
													:dialog-params="params"
													class="proc-dialog__panels"
													@loaded="onLoad"
													@close="onClose"/>-->
				<!-- или это простая выборка -->
				<proc v-else-if="params.id"
					  :id="params.id"
					  :params="params.params"
					  :hide-buttons="params.hideButtons"
					  :readonly="params.readonly"
					  :with-open="params.withOpen"
					  :with-header="params.withHeader"
					  @loaded="onLoad"
					  @close="onClose"
					  @click="onClick"
					  @open="onOpen"/>
			</v-card-text>
		</v-card>
	</v-dialog>

</template>

<script>
import Vue from "vue";
import vp from "@/mixins/vp";
import {md5} from "@/utils/string";
import Config from "@/config";
import {debounce} from "@/utils/utils";

export default {
	name: "proc-dialog",
	props: ["params"],
	mixins: [vp],
	data: () => ({
		dialogWidth: 400,
		height: 280,
		isDataLoaded: false,
		isFullscreen: false,

		data: null,
		isLoading: true,
		isUpdatingSave: false,
		isUpdatingSaveAndClose: false,
		initialHash: null,
		initialHashBlobs: null,
		title: "Загрузка...",
	}),
	computed: {
		isCard() {
			return this.params?.isCard;
		},
		isFormView() {
			return !!this.params?.formId;
		},
		formHeight() {
			return window.innerHeight;// * .88;
		},
		meta() {
			return this.data?.meta;
		},
		ui() {
			return this.data?.ui;
		},
		cardDS() {
			// ищем ds-выборку по параметрам диалога
			return this.$store.state.formProcs.find(el => el.formId === this.params.formId && el.procNameId === this.params.id);
		},
		/**
		 * Возвращает элемент, редактируемый в карточке (при params.isCard===true)
		 * @returns {null}
		 */
		cardItem() {
			//if (!this.isCard) return null;
			//console.log("cardDS", this.cardDS);
			return this.cardDS?.item;
		},
		cardBlobs() {
			//if (!this.isCard) return null;
			return this.cardDS?.blobs;
		},
		withCardButtons() {
			return this.isCard || this.ui?.find(el => el.COMPNAME?.match(/fmForm/i) && el.tag);
		},
		isChanged() {
			return (this.initialHash && this.initialHash !== md5(this.cardItem))
				|| (this.initialHashBlobs && this.initialHashBlobs !== md5(this.cardBlobs))
		},
		hasContent() {
			return this.isDataLoaded || !this.ui;
		}

	},
	watch: {
		"params.isVisible"(val, old) {
			if (val) {
				this.resetView();
				this.applyDialogSize();
				if (this.params.formId) this.fetchFormDebounced();
			}
			//else this.onClose();
		},
		/*"params.formId": {
			immediate: true,
			handler(val, old) {
				if (val && val !== old) {
					this.fetchForm();
				}
			}
		},*/
		cardItem() {
			if (!this.initialHash) this.initialHash = md5(this.cardItem);
		},
		cardBlobs() {
			if (!this.initialHashBlobs) this.initialHashBlobs = md5(this.cardBlobs);
		},
	},
	methods: {
		onClose() {
			this.params.isVisible = false;
			this.$emit("close");
			if (this.params.callback) {
				//console.log("dialog onClose calling callback");
				this.params.callback();
				this.params.callback = null;
			}
			if (this.params.isParentless) {
				this.$router.push({name: "home"});
			}
		},
		onSave() {
			this.isUpdatingSave = true;
			this.updateCardItem();
		},
		onSaveAndClose() {
			this.isUpdatingSaveAndClose = true;
			this.updateCardItem().then(() => this.onClose());
		},
		onResize() {
			// TODO this behaviour should be tested
			if (this.isDataLoaded && !this.isCard && this.isFullscreen) this.height = this.formHeight;
		},
		onLoad(data) {
			this.isDataLoaded = true;
			//console.log("FORM OR PROC LOADED", data);

			// smal pause to apply reactivity
			setTimeout(() => {
				if (this.isFormView) this.title = data?.ui?.find(el => el.COMPNAME.match(/fmForm/))?.caption;
				else this.title = data?.meta?.CAPTION;

				this.applyDialogSize();
			}, 150);
		},
		onClick(item) {
			this.$emit("click", item);
		},
		onOpen(item) {
			this.$emit("open", item);
			if (this.params.callback) {
				this.params.callback();
				this.params.callback = null;
			}
		},
		applyDialogSize() {
			// pause for panels.vue to build UI
			if (this.isLG) {
				const form = this.data?.ui?.find(el => el.COMPNAME.match(/^fmForm/));
				//console.log("applyDialogSize", form);
				if (form) {
					if (form.isMaximized) {
						this.isFullscreen = true;
						//this.dialogWidth = "100%";
						this.height = this.formHeight;
					} else {
						this.isFullscreen = false;
						this.dialogWidth = (+form.clientWidth || +form.width) + 4 + 4;	// 4 + 4 is padding
						this.height = (+form.clientHeight || +form.height) + 6 + 4 + 4;	// 6 is smth calculated visually, 4+4 is padding

						//console.log("form.clientWidth", form.clientWidth, form.width);
						//console.log("form.clientHeight", form.clientHeight, form.height);
					}
				} else {
					// no form yet loaded
					if (this.isFormView) {
						this.isFullscreen = false;
						this.dialogWidth = 320;
						this.height = 280;
					} else {
						this.isFullscreen = true;
						//this.dialogWidth = "100%";
						this.height = this.formHeight;
					}
				}
			} else {
				// mobile - always fullscreen
				this.isFullscreen = true;
				//this.dialogWidth = "100%";
				this.height = this.formHeight;
			}
		},
		updateCardItem() {
			//console.log("params", this.params);

			this.$store.dispatch("log", {
				ID_OBJECT: this.params.id,
				ID_KEYFIELD: this.cardItem[this.cardDS.meta.KEYFIELD],
				ID_WEBLOGTYPE: this.params.item ? Config.ID_WEBLOGTYPE_UPDATE : Config.ID_WEBLOGTYPE_INSERT,
			});

			return new Promise((resolve, reject) => {

				// append params with parent DS params
				const params = {...(this.params?.params || {}), ...(this.cardDS?.params || {})};

				this.$store.dispatch(this.params.item ? "put" : "post", {
					action: "ItemController",
					params: {
						procNameId: this.params.id,
						item: this.cardItem,
						oldItem: this.params.item,
						blobs: this.cardBlobs,
						params
					},
				})
					.then(res => {
						//console.log("RES", res);
						//console.log("PARAMS", this.params);

						this.initialHash = md5(this.cardItem);
						this.initialHashBlobs = md5(this.cardBlobs);

						// находим выборку в родительском окне (под диалогом) - та, из которой открыли диалог - опционально помечаем ее на refetch
						// здесь ищем без идентификатора формы - это ок по идее, так как, если таких выборок несколько - они все должны зареветчиться
						const parentProc = this.$store.state.formProcs.find(el => el.procNameId === this.params.parentId);

						let wasNew = false;
						if (this.params.item) {
							//console.log("this.params.item", this.params.item);
							//console.log("this.$store.state.formProcs", this.$store.state.formProcs);
							//console.log("this.params.parentId", this.params.parentId);
							// мы обновили существующий элемент
							if (parentProc) {
								//console.log("parentProc", parentProc);
								// mark parent proc to refresh current item/refresh in full
								if (parentProc.meta?.IS_REOPENAFTERCARD) this.$store.state.procIdsWithRefresh.push(parentProc.procNameId);
								else this.$store.state.procIdsWithRefreshItem.push(parentProc.procNameId);
								//console.log("this.$store.state.procIdsWithRefreshItem", {...this.$store.state.procIdsWithRefreshItem});
							}
							resolve();
						} else {
							// мы создали новый элемент (в карточке)
							wasNew = true;

							if (res?.page?.meta?.KEYFIELD && res.page.values) {
								// инициализируем его ключевое поле
								this.cardItem[res?.page?.meta?.KEYFIELD] = res.page.values[res?.page?.meta?.KEYFIELD];
							}
							// сообщаем всем, кто DS, что элемент обновлен - они перефетчиваются, если здесь появился элемент
							this.params.item = {...this.cardItem};
							//console.log("SETTING params.item", this.params.item);

							if (parentProc) {
								//this.$set(parentProc, "item", this.params.item);
								// mark parent proc to refresh in full
								if (parentProc.meta?.IS_REOPENAFTERCARD) this.$store.state.procIdsWithRefresh.push(parentProc.procNameId);
							}

							// ждем, что DS карточки перечитался - так как нам важно, чтобы прошел ее registerProc()
							let counter = 0;
							const interval = setInterval(() => {
								const isLoaded = !this.cardDS.isLoading;
								console.log("Checking DS is reloaded", isLoaded);
								if (isLoaded || counter++ >= 20) {
									clearInterval(interval);
									resolve();
								}
							}, 250);
						}

						// вывести сообщение
						if (res?.page?.msg) {
							this.$store.state.snackbar.success = res?.page?.msg;
							this.$store.state.snackbar.timeout = -1;
						}/* else if (wasNew) this.$store.state.snackbar.success = "Элемент добавлен";
						else this.$store.state.snackbar.success = "Изменения сохранены";*/
					})
					.catch(err => {
						console.error("ERROR", err);
						this.$store.state.snackbar.error = err.error;
						reject(err);
					})
					.finally(() => {
						this.isUpdatingSave = false;
						this.isUpdatingSaveAndClose = false;
					});
			});
		},
		fetchFormDebounced: debounce(function () {
			this.fetchForm();
		}, 150),
		fetchForm() {
			//console.log("Loading form", this.params);
			this.isLoading = true;
			return this.$store.dispatch("get", {
				action: "FormController",
				params: {
					formId: this.params.formId,
					procNameId: this.params.id,
				},
			})
				.then(res => {
					this.data = res.page;
					//this.$emit("loaded", this.data);
					this.onLoad(this.data);
				})
				.catch(err => {
					console.error("ERROR", err);
					this.$store.state.snackbar.error = err.error;
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		resetView() {
			this.title = "Загрузка...";
			this.data = null;
			this.initialHash = null;
			this.initialHashBlobs = null;
		}
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.onResize();
		if (this.params.formId) this.fetchFormDebounced();
	},
	destroyed() {
		window.removeEventListener("resize", this.onResize);
	}
}
</script>

<style lang="scss">

.proc-dialog {
	overflow: hidden;
	//background: $table-bg-color;

	&__card {
		@include up($lg) {
			display: flex;
			flex-direction: column;
			border: 4px solid $pale-blue-color !important;
			//padding-top: 20px;

			/*.v-card__text {

				& > .proc {
					background: $pale-blue-color;

					.proc__body {
						background: $white;
					}
				}

			}*/
		}

		.v-card__text {
			padding: 0 !important;
		}

		&-actions {
			&-content {
				flex: 1;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				@include up($lg) {
					justify-content: space-between;
				}
			}
		}
	}

	&__title {
		flex: 1;
		//padding: 10px 16px !important;

		@include up($lg) {
			padding: 0 !important;
		}
	}

	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	&__panels {
		width: 100%;
		flex: 1;
		padding: 4px;
	}

	&__loader {
		//background: $table-bg-color;
		height: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
</style>